import { createListCollection, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ONBOARDING_STATE_KEY } from 'apis/dashboard-apis';
import {
  addPhysicalNexus,
  getPhysicalNexusCategories,
  PHYSICAL_NEXUS_CATEGORIES_KEY,
  PHYSICAL_NEXUS_KEY,
  updatePhysicalNexus,
} from 'apis/physical-nexus-apis';
import KCustomSelect from 'components/k-custom-select/k-custom-select';
import { Button } from 'components/ui/button';
import DatePicker from 'components/ui/date-picker';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { Field } from 'components/ui/field';
import {
  PresenceSubCategorySelectItem,
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
} from 'components/ui/select';
import { Tooltip } from 'components/ui/tooltip';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useLocationData } from 'hooks/useLocationData';
import { useOrg } from 'hooks/useOrg';
import useTracking from 'hooks/useTracking';
import { useEffect, useMemo, useRef, useState } from 'react';
import { MdInfo } from 'react-icons/md';
import { LocationOptionType } from 'types/location';
import { CountryCodeEnum, PhysicalNexusInstance } from 'types/shared-types';
import * as Yup from 'yup';
import { date, object, string } from 'yup';

const validationSchema = object().shape({
  country_code: string().required('Country is required'),
  state: string().required('State is required'),
  category: string().required('Category is required'),
  start_date: date().required('Start date is required'),
  end_date: date()
    .nullable()
    .optional()
    .test('not-future', 'End date can not be greater than today', function (value) {
      if (!value) return true;
      return new Date(value) <= new Date();
    })
    .min(Yup.ref('start_date'), "End date can't be before start date"),
});

type PhysicalNexusModalProps = {
  isOpen: boolean;
  onClose: () => void;
  physicalNexus?: PhysicalNexusInstance;
};

export const PhysicalNexusModal = ({ isOpen, onClose, physicalNexus }: PhysicalNexusModalProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { orgId } = useOrg();
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();
  const { track } = useTracking();
  const queryClient = useQueryClient();

  const { countries, getStates } = useLocationData();
  const [stateOptions, setStateOptions] = useState<LocationOptionType[]>([]);

  const [selectedCountry, setSelectedCountry] = useState<CountryCodeEnum>(
    physicalNexus?.country_code ?? CountryCodeEnum.US
  );
  const [selectedState, setSelectedState] = useState<string | undefined>(physicalNexus?.state_code);
  const isCategoryQueryEnabled = !!selectedCountry && !!selectedState;

  const { data: categories = [] } = useQuery({
    queryKey: [PHYSICAL_NEXUS_CATEGORIES_KEY, selectedCountry, selectedState],
    queryFn: async () => {
      const res = await getPhysicalNexusCategories(orgId, selectedCountry, selectedState);
      return res.data;
    },
    enabled: isCategoryQueryEnabled,
  });

  const { isPending: isCreationPending, mutateAsync: doCreatePhysicalNexus } = useMutation({
    mutationFn: (payload: any) => {
      return addPhysicalNexus(orgId, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PHYSICAL_NEXUS_KEY] });
      queryClient.invalidateQueries({ queryKey: [ONBOARDING_STATE_KEY] });
      handleSuccessNotification(
        "Physical Presence Successfully Added. You can check the 'Nexus' tab in a few minutes to see updates to your Nexus"
      );
    },
    onError: error => {
      handleFailNotification(error);
    },
  });

  const { isPending, mutateAsync: doUpdatePhysicalNexus } = useMutation({
    mutationFn: (payload: { id: string; data: object }) => {
      return updatePhysicalNexus(orgId, payload.id, payload.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PHYSICAL_NEXUS_KEY] });
      queryClient.invalidateQueries({ queryKey: [ONBOARDING_STATE_KEY] });
      handleSuccessNotification('Physical Nexus added successfully.');
    },
    onError: error => {
      handleFailNotification(error);
    },
  });

  const initialValues = physicalNexus
    ? {
        country_code: physicalNexus.country_code,
        state: physicalNexus.state_code,
        category: physicalNexus.category,
        start_date: physicalNexus.start_date,
        end_date: physicalNexus.end_date,
      }
    : {
        country_code: [CountryCodeEnum.US],
        state: [],
        category: [],
        start_date: '',
        end_date: '',
      };

  const isDateValid = (dateStr: string) => {
    return !isNaN(new Date(dateStr).getTime());
  };

  const { errors, touched, resetForm, values, handleSubmit, setFieldValue, setFieldTouched, isValid, dirty } =
    useFormik({
      initialValues,
      validationSchema: validationSchema,
      onSubmit: async ({ country_code, state, category, start_date, end_date }) => {
        if (physicalNexus) {
          await doUpdatePhysicalNexus({
            id: physicalNexus.id,
            data: {
              country_code,
              state_code: state,
              category,
              start_date,
              end_date: end_date && isDateValid(end_date) ? values.end_date : undefined,
            },
          });
          track('update presence', { action: 'physical_nexus' });
        } else {
          await doCreatePhysicalNexus({
            country_code,
            state_code: state,
            category,
            start_date,
            end_date: end_date && isDateValid(end_date) ? values.end_date : undefined,
          });
          track('adds presence', { action: 'physical_nexus' });
        }
        resetForm({});
        onClose();
      },
    });

  useEffect(() => {
    const fetchStates = async () => {
      const states = await getStates((values.country_code as string) ?? '');
      setStateOptions(states);
    };
    fetchStates();
  }, [getStates, values.country_code]);

  const isEditModal = !!physicalNexus;
  const modalSubHeader = isEditModal
    ? "Once you update the details, you'll need to contact support to make further changes. If you are looking to test, please use a test site."
    : 'Once you add them, you will need to contact support to edit or delete them. If you are looking to test, please use a test site.';

  const countriesCollection = useMemo(
    () =>
      createListCollection({
        items: countries,
      }),
    [countries]
  );

  const statesCollection = useMemo(() => createListCollection({ items: stateOptions }), [stateOptions]);

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open }) => {
        if (!open) {
          onClose();
        }
      }}
      size={'lg'}
    >
      <DialogBackdrop />
      <DialogContent colorPalette="blue">
        <DialogHeader>
          <DialogTitle>{isEditModal ? 'Edit' : 'New'} Presence</DialogTitle>
        </DialogHeader>
        <DialogCloseTrigger />

        <DialogBody ref={contentRef} display={'grid'} flexDirection={'column'} gap={2}>
          <Text mb={2}>{modalSubHeader}</Text>
          <SimpleGrid columns={2} gap={4}>
            <Field label="Country" required>
              <SelectRoot
                collection={countriesCollection}
                value={values.country_code ? ([values.country_code] as string[]) : undefined}
                onValueChange={({ value }) => {
                  setFieldValue('country_code', value[0]);
                  setSelectedCountry(value[0] as CountryCodeEnum);
                  setFieldValue('state', []);
                  setSelectedState(undefined);
                }}
                disabled={isEditModal}
              >
                <SelectTrigger>
                  <SelectValueText />
                </SelectTrigger>
                <SelectContent portalRef={contentRef}>
                  {countriesCollection.items.map(item => (
                    <SelectItem key={item.value} item={item}>
                      {item.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </SelectRoot>
            </Field>
            <Field label="Jurisdiction" invalid={!!(errors.state && touched.state)} errorText={errors.state} required>
              <SelectRoot
                collection={statesCollection}
                value={values.state ? ([values.state] as string[]) : undefined}
                onValueChange={({ value }) => {
                  setFieldValue('state', value[0]);
                  setSelectedState(value[0]);
                }}
                disabled={isEditModal}
              >
                <SelectTrigger>
                  <SelectValueText />
                </SelectTrigger>
                <SelectContent portalRef={contentRef}>
                  {statesCollection.items.map(item => (
                    <SelectItem key={item.value} item={item}>
                      {item.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </SelectRoot>
            </Field>
          </SimpleGrid>
          <SimpleGrid columns={1}>
            <Field
              label="Category"
              invalid={!!(errors.category && touched.category)}
              errorText={errors.category}
              required
            >
              <KCustomSelect
                enableSearch
                id="category"
                name="category"
                placeholder="Select Category"
                items={categories}
                itemToStringField="title"
                itemToValueField="name"
                value={values.category ? ([values.category] as string[]) : undefined}
                onValueChange={({ value }) => {
                  setFieldValue('category', value[0]);
                }}
                customSelectItemContent={item => (
                  <PresenceSubCategorySelectItem
                    item={item}
                    tooltip={true}
                    disabled={item.is_category_assigned && item.name !== physicalNexus?.category}
                  />
                )}
              />
            </Field>
          </SimpleGrid>
          <SimpleGrid columns={2} gap={4}>
            <Field
              label="Start Date"
              invalid={!!(errors.start_date && touched.start_date)}
              errorText={errors.start_date}
              required
            >
              <DatePicker
                selected={values.start_date}
                onChange={date => {
                  setFieldValue('start_date', date);
                }}
                disabled={isEditModal}
              />
            </Field>
            <Field
              label={
                <Flex align="center" gap="0.5">
                  End Date{' '}
                  {(!isEditModal || (isEditModal && !physicalNexus.end_date)) && (
                    <Tooltip
                      contentProps={{ maxWidth: 'lg' }}
                      content={
                        <Text>
                          You can optionally add an end date to a presence if you no longer have an employee,
                          contractor, or other form of presence in a jurisdiction.
                        </Text>
                      }
                    >
                      <MdInfo />
                    </Tooltip>
                  )}
                </Flex>
              }
              invalid={!!(errors.end_date && touched.end_date)}
              errorText={errors.end_date}
            >
              <DatePicker
                selected={values.end_date}
                onChange={async date => {
                  await setFieldValue('end_date', date);
                  await setFieldTouched('end_date', true);
                }}
                disabled={!!physicalNexus?.end_date && isEditModal}
              />
            </Field>
          </SimpleGrid>
        </DialogBody>
        <DialogFooter>
          <Flex gap={2}>
            <Button variant={'outline'} color={'secondary'} onClick={onClose}>
              Cancel
            </Button>
            <Button
              loading={isPending || isCreationPending}
              disabled={!isValid || !dirty}
              variant={'solid'}
              color={'primary'}
              width={'90px'}
              onClick={() => handleSubmit()}
            >
              Save
            </Button>
          </Flex>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};
