import { Flex, Table, Text } from '@chakra-ui/react';
import { KDataTable } from 'components/table/data-table';
import { TableEmptyState } from 'components/table-empty-state/table-empty-state';
import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from 'components/ui/menu';
import { TableRowSkeleton } from 'components/ui/table-row-skeleton';
import { Tooltip } from 'components/ui/tooltip';
import { useState } from 'react';
import { MdMoreVert } from 'react-icons/md';
import { PHYSICAL_NEXUS_CATEGORIES_MAP } from 'types/nexus';
import { PhysicalNexusInstance } from 'types/shared-types';
import { getCountryDisplay } from 'utils';
import { toDateShort } from 'utils/dates';

import { RemovePhysicalNexus } from './remove-physical-nexus-modal';

type PhysicalNexusTableProps = {
  data: PhysicalNexusInstance[];
  isTransactionAvailable: boolean;
  onEdit: (physicalNexus: PhysicalNexusInstance) => void;
  isPending: boolean;
};

const TABLE_HEAD = ['Country', 'Jurisdiction', 'Category', 'Start Date', 'End Date'];
const COLUMN_WIDTHS = {
  Country: '80px',
  Jurisdiction: '100px',
  Category: '220px',
  'Start Date': '100px',
  'End Date': '100px',
};

export const PhysicalNexusTable = ({ data, isTransactionAvailable, onEdit, isPending }: PhysicalNexusTableProps) => {
  const isDataEmpty = !data || data.length === 0;

  if (isDataEmpty && !isPending) {
    return (
      <TableEmptyState
        tableName="Presence"
        uniqueHelperTxt="Please add a presence or connect your payroll system to manage your presence details."
      />
    );
  }

  return (
    <KDataTable headers={TABLE_HEAD} columnWidths={COLUMN_WIDTHS} isPending={isPending}>
      {data.map((row: PhysicalNexusInstance) => {
        return (
          <TableRow
            key={row.state_code}
            physicalNexus={row}
            isTransactionAvailable={isTransactionAvailable}
            onEdit={onEdit}
          />
        );
      })}
      {isPending && <TableRowSkeleton length={25} columns={TABLE_HEAD} />}
    </KDataTable>
  );
};

type TableRowProps = {
  physicalNexus: PhysicalNexusInstance;
  isTransactionAvailable: boolean;
  onEdit: (physicalNexus: PhysicalNexusInstance) => void;
};

const TableRow = ({ physicalNexus, isTransactionAvailable, onEdit }: TableRowProps) => {
  const [confirmRemovePopup, setConfirmRemovePopup] = useState<{ id: string; name: string } | null>(null);
  const isPresenceNonEditable = isTransactionAvailable && !!physicalNexus.end_date;

  const categoryTitle = PHYSICAL_NEXUS_CATEGORIES_MAP[physicalNexus?.category]?.title || 'Unknown Category';

  return (
    <>
      <Table.Row>
        <Table.Cell width={{ sm: '80px' }}>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{getCountryDisplay(physicalNexus.country_code)}</Text>
          </Flex>
        </Table.Cell>
        <Table.Cell width={{ sm: '100px' }}>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{physicalNexus.state_code}</Text>
          </Flex>
        </Table.Cell>
        <Table.Cell width={{ sm: '220px' }}>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{categoryTitle}</Text>
          </Flex>
        </Table.Cell>
        <Table.Cell width={{ sm: '100px' }}>
          <Flex alignItems={'center'} justifyContent={'space-between'}>
            <Text>{toDateShort(physicalNexus.start_date)}</Text>
          </Flex>
        </Table.Cell>
        <Table.Cell width={{ sm: '100px' }}>
          <Flex alignItems={'center'} justifyContent={'space-between'}>
            <Text>{toDateShort(physicalNexus.end_date)}</Text>
            <MenuRoot>
              <MenuTrigger asChild>
                <MdMoreVert size={'20px'} />
              </MenuTrigger>
              <MenuContent>
                <Tooltip disabled={!isPresenceNonEditable} content={'Please contact support to edit presence'}>
                  <MenuItem value="edit" disabled={isPresenceNonEditable} onClick={() => onEdit(physicalNexus)}>
                    Edit
                  </MenuItem>
                </Tooltip>
                <Tooltip content={isTransactionAvailable ? 'Please contact support to remove presence' : ''}>
                  <MenuItem
                    value="remove"
                    disabled={isTransactionAvailable}
                    onClick={() => {
                      setConfirmRemovePopup({ id: physicalNexus.id, name: physicalNexus.state_code });
                    }}
                    color={'red.500'}
                  >
                    Remove
                  </MenuItem>
                </Tooltip>
              </MenuContent>
            </MenuRoot>
          </Flex>
        </Table.Cell>
      </Table.Row>
      {confirmRemovePopup?.id && (
        <RemovePhysicalNexus
          physicalNexusId={confirmRemovePopup.id}
          stateName={confirmRemovePopup.name}
          onClose={() => {
            setConfirmRemovePopup(null);
          }}
        />
      )}
    </>
  );
};
